<template>
  <div>
    <h4 class="font-weight-bold pb-1 indigo--text">Gestión</h4>
    <h5 class="font-weight-light">Gestión de Proyectos</h5>
    <v-form ref="formulario" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12">
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Antecedentes Proyecto
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nombre proyecto"
                          v-model="datosFormulario.nombre"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nº Proyecto"
                          v-model="datosFormulario.nro_proyecto"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="tipos_formalizacion"
                          v-model="datosFormulario.tipo_formalizacion"
                          item-value="tipo"
                          item-text="text"
                          label="Tipo Formalización"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nº Documento Formalización"
                          v-model="datosFormulario.nro_documento_formalizacion"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Monto documento"
                          v-model="datosFormulario.monto_documento"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="contactos_mandantes"
                          v-model="datosFormulario.contacto_mandante"
                          item-value="_id"
                          item-text="nombre"
                          label="Contacto"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="encargados"
                          v-model="datosFormulario.encargado"
                          item-value="_id"
                          item-text="nombre"
                          label="Encargado del proyecto IPC"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="estados"
                          v-model="datosFormulario.status"
                          item-value="tipo"
                          item-text="text"
                          label="Estado"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-switch
                          v-model="datosFormulario.hes"
                          label="OC/HES"
                          color="primary"
                          hide-details
                          @change="getHeaders()"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-switch
                          v-model="datosFormulario.ito"
                          label="Proyecto compartido ITO"
                          color="primary"
                          hide-details
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-switch
                          v-model="datosFormulario.cubicacion"
                          label="Cubicacion"
                          color="primary"
                          hide-details
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-switch
                          v-model="datosFormulario.presupuestos[0].valorRevit > 0"
                          label="Revit"
                          color="primary"
                          hide-details
                          disabled
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>

              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Condiciones del proyecto
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :items="datosFormulario.condiciones"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:item.monto="{ item }">
                        {{ item.monto | formato_numero }}
                      </template>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Condiciones</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialog" max-width="550px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                class="mb-2 indigo lighten-5 primary--text elevation-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Nueva condición
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row> </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.nro_hes"
                                        outlined
                                        hide-details
                                        label="Nº OC/HES"
                                        v-if="datosFormulario.hes"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.concepto"
                                        outlined
                                        hide-details
                                        label="Concepto"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.monto"
                                        outlined
                                        hide-details
                                        label="Monto"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            label="Fecha Estimada de realización"
                                            v-model="
                                              editedItem.fecha_estimada_realizacion
                                            "
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            hide-details
                                            required
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="editedItem.fecha_estimada_realizacion"
                                          locale="es-es"
                                          @input="menu3 = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                  Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                              <v-card-title class="headline"
                                >Estás seguro de eliminar esta condición?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirm"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.activo="{ item }">
                        <v-chip :color="getEstados(item.activo)" dark small>{{
                          getEstadosTexto(item.activo)
                        }}</v-chip>
                      </template>
                      <template v-slot:item.accion="{ item }">
                        <v-icon small class="mr-2 blue--text" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          class="red--text text--darken-4"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>

              <v-col cols="12" lg="12" v-if="idDato">
                <v-card-title class="font-weight-light text-overline">
                  Eventos del proyecto
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headersEventos"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :items="datosFormulario.eventos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:item.monto="{ item }"> </template>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Eventos</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialogEventos" max-width="550px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                class="mb-2 indigo lighten-5 primary--text elevation-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Nuevo evento
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="headline">{{ formTitleEventos }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row> </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItemEventos.evento"
                                        outlined
                                        hide-details
                                        label="Actividad"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-textarea
                                        outlined
                                        label="Notas de la actividad"
                                        v-model="editedItemEventos.detalle"
                                        hide-details
                                      ></v-textarea>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                      <v-menu
                                        v-model="menu3Eventos"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            label="Fecha Estimada de realización"
                                            v-model="
                                              editedItemEventos.fecha_estimada_realizacion
                                            "
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            hide-details
                                            required
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            editedItemEventos.fecha_estimada_realizacion
                                          "
                                          locale="es-es"
                                          @input="menu3Eventos = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeEventos">
                                  Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="saveEventos">
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogDeleteEventos" max-width="500px">
                            <v-card>
                              <v-card-title class="headline"
                                >Estás seguro de eliminar este evento?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeDeleteEventos"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirmEventos"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.activo="{ item }">
                        <v-chip :color="getEstados(item.activo)" dark small>{{
                          getEstadosTexto(item.activo)
                        }}</v-chip>
                      </template>
                      <template v-slot:item.detalle="{ item }">
                        <span style="white-space: pre">{{ item.detalle }}</span>
                      </template>
                      <template v-slot:item.accion="{ item }">
                        <v-icon
                          small
                          class="mr-2 blue--text"
                          @click="editItemEventos(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          class="red--text text--darken-4"
                          @click="deleteItemEventos(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="idDato"
                @click="editData"
                right
                >Editar</v-btn
              >
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="!idDato"
                @click="saveData"
                right
                >Grabar</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Presupuestos
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-card>
                      <v-tabs
                        v-model="tab"
                        background-color="primaryaccent"
                        color="primary"
                      >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab
                          v-for="item in datosFormulario.presupuestos"
                          :key="item._id"
                        >
                          {{ item.nro_presupuesto }}
                        </v-tab>
                        <div
                          v-if="datosFormulario.presupuestos.length < 1"
                          class="m-2 mx-auto"
                          align="center"
                        >
                          <b>Sin presupuestos asignados</b>
                        </div>
                      </v-tabs>

                      <v-tabs-items v-model="tab">
                        <v-tab-item
                          v-for="item in datosFormulario.presupuestos"
                          :key="item._id"
                        >
                          <v-card flat>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Nº presupuesto:
                                  </span>
                                  {{ item.nro_presupuesto }}
                                </v-col>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Nombre del presupuesto:
                                  </span>
                                  {{ item.nombreProyecto }}
                                </v-col>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Monto presupuesto:
                                  </span>
                                  {{ item.valor | filtro_valor(item.tipo_moneda) }}
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="indigo lighten-5"
                                class="mb-2 indigo lighten-5 primary--text"
                                v-if="idDato"
                                @click="
                                  $router.push({
                                    name: 'presupuesto',
                                    params: { id: item._id },
                                  })
                                "
                                right
                                >Ver detalle del Presupuesto</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-3 mt-5" v-if="idDato">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Documentos
                  <v-spacer></v-spacer>
                  <v-btn
                    color="indigo lighten-5"
                    class="mb-2 indigo lighten-5 primary--text"
                    @click="dialogNuevoDocumento = true"
                    >Nuevo Documento</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers_documentos"
                      :items="datosFormulario.archivos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:item.accion="{ item }">
                        <v-icon
                          small
                          v-if="usuarioDB.data.role == 'ADMIN'"
                          class="mr-2 red--text text--darken-4"
                          @click="deleteDocumento(item._id)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <template v-slot:item.fecha_creacion="{ item }">
                        {{ item.fecha_creacion | fecha_normal }}
                      </template>
                      <template v-slot:item.archivo="{ item }">
                        <v-btn icon color="green">
                          <v-icon @click="abrirArchivo(item.archivo)"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogNuevoDocumento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Nuevo documento </v-card-title>
          <v-card-text class="mt-4">
            <v-text-field
              label="Nombre del documento"
              v-model="nombreArchivo"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-file-input
              v-model="archivotemp"
              hide-details
              label="Documento"
              type="file"
              ref="file"
              @change="selectFile"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogNuevoDocumento = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 grey"
              disabled
              v-if="files == null"
              >Subir documento</v-btn
            >
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="subirDocumento()"
              v-else
              >Subir documento</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
class datosReporte {
  constructor(datos = {}) {
    this.nombre = datos.nombre;
    this.nro_proyecto = datos.nro_proyecto;
    this.tipo_formalizacion = datos.tipo_formalizacion;
    this.nro_documento_formalizacion = datos.nro_documento_formalizacion;
    this.monto_documento = datos.monto_documento;
    this.contacto_mandante = datos.contacto_mandante;
    this.hes = datos.hes;
    this.cubicacion = datos.cubicacion;
    this.encargado = datos.encargado;
    this.ito = datos.ito;
    this.condiciones = datos.condiciones;
    this.status = datos.status;
    this.presupuestos = datos.presupuestos;
    this.archivos = datos.archivos;
    this.eventos = datos.eventos;
  }
}

export default {
  props: ["presupuesto"],
  data() {
    return {
      valid: true,
      basic_validation_rules_input: [
        (v) => !!v || "Campo obligatorio",
        (v) => (v && v.length <= 50) || "Campo requerido",
      ],
      basic_validation_rules_email: [
        (v) => !!v || "E-mail es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Debe ingresar un Email válido",
      ],
      basic_validation_rules_select: [(v) => !!v || "Campo obligatorio"],

      loading: false,
      idDato: null,
      ex11: true,

      tipo: [
        {
          tipo: "1",
          text: "Proyectos",
        },
        {
          tipo: "2",
          text: "ITO",
        },
      ],
      tipos_moneda: [
        {
          tipo: "1",
          text: "U.F",
        },
        {
          tipo: "2",
          text: "Pesos Chilenos",
        },
      ],
      estados: [
        {
          tipo: 1,
          text: "Activo",
        },
        {
          tipo: 2,
          text: "Congelado",
        },
        {
          tipo: 3,
          text: "Finalizado",
        },
      ],
      tipos_formalizacion: [
        {
          tipo: 1,
          text: "OC",
        },
        {
          tipo: 2,
          text: "E-Mail",
        },
        {
          tipo: 3,
          text: "Otro",
        },
      ],

      datosFormulario: new datosReporte(new datosReporte()),

      seleccionados: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      menu3Eventos: false,
      menu4: false,
      noresult: "No existen resultados",
      search: "",
      sortBy: "ceco",
      sortDesc: false,
      headers: [
        {
          text: "Concepto",
          align: "start",
          value: "concepto",
        },
        {
          text: "Monto",
          align: "start",
          value: "monto",
        },
        {
          text: "Fecha Estimada Realización",
          align: "start",
          value: "fecha_estimada_realizacion",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: false,
        },
      ],
      headersEventos: [
        {
          text: "Actividad",
          align: "start",
          value: "evento",
        },
        {
          text: "Detalle de la actividad",
          align: "start",
          value: "detalle",
        },
        {
          text: "Fecha Estimada Realización",
          align: "start",
          value: "fecha_estimada_realizacion",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: false,
        },
      ],
      editedIndex: -1,
      editedIndexEventos: -1,
      editedItem: {
        nro_hes: "",
        concepto: "",
        monto: "",
        fecha_estimada_realizacion: "",
        fecha_realizacion: "",
        fecha_registro_realizacion: "",
        estado: 1,
      },
      defaultItem: {
        nro_hes: "",
        concepto: "",
        monto: "",
        fecha_estimada_realizacion: "",
        fecha_realizacion: "",
        fecha_registro_realizacion: "",
        estado: 1,
      },
      editedItemEventos: {
        evento: "",
        detalle: "",
        fecha_estimada_realizacion: "",
        fecha_realizacion: "",
        fecha_registro_realizacion: "",
        estado: 1,
      },
      defaultItemEventos: {
        evento: "",
        detalle: "",
        fecha_estimada_realizacion: "",
        fecha_realizacion: "",
        fecha_registro_realizacion: "",
        estado: 1,
      },
      dialog: false,
      dialogDelete: false,
      dialogEventos: false,
      dialogDeleteEventos: false,

      mandantes: [],

      tab: null,
      items: [
        { tab: "PR-1234", content: "Tab 1 Content" },
        { tab: "PR-5342", content: "Tab 2 Content" },
      ],
      items2: [],
      opcionesProyecto: [
        {
          tipo: 1,
          texto: "Nuevo Proyecto",
        },
        {
          tipo: 2,
          texto: "Proyecto existente",
        },
      ],
      offset: true,
      status_bd: "0",
      contactos_mandantes: [],
      encargados: [],
      files: null,
      nombreArchivo: "",
      dialogNuevoDocumento: false,
      headers_documentos: [
        {
          text: "Nombre Documento",
          align: "start",
          value: "nombre",
        },
        {
          text: "Subido por",
          align: "start",
          value: "responsable.nombre",
        },
        {
          text: "Fecha carga",
          align: "start",
          value: "fecha_creacion",
        },
        {
          text: "Documento",
          align: "start",
          value: "archivo",
        },
        {
          text: "Accion",
          value: "accion",
          sortable: false,
        },
      ],
      archivotemp: null,
    };
  },
  methods: {
    abrirArchivo(archivo) {
      window.open(process.env.VUE_APP_BASE_URL + "/files/proyectos/" + archivo, "_blank");
    },
    selectFile(file) {
      this.files = file;
    },
    async subirDocumento() {
      this.loading = true;

      let config = {
        headers: {
          token: this.token,
          "Content-Type": "multipart/form-data;",
        },
      };

      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("nombre", this.nombreArchivo);
      formData.append("idProyecto", this.idDato);

      await axios
        .put("/proyectos/subirDocumento/", formData, config)
        .then((response) => {
          this.loading = false;
          this.files = null;
          this.nombreArchivo = "";
          this.archivotemp = null;
          this.dialogNuevoDocumento = false;
          this.getData();
          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    async deleteDocumento(item) {
      //this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/proyectos/eliminarDocumento/" + item + "/" + this.idDato, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    validate() {
      this.$refs.formulario.validate();
    },
    validaRut: function (rutCompleto) {
      //rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";

      return this.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    lg(tipo) {
      if (tipo == 1) {
        return this.usuarioDB.data.role == "ADMIN" ? 6 : 12;
      } else {
        return this.usuarioDB.data.role == "ADMIN" ? 12 : 6;
      }
    },
    getHeaders() {
      console.log(this.datosFormulario.hes);
      if (this.datosFormulario.hes) {
        this.headers = [
          {
            text: "Nº OC/HES",
            align: "start",
            value: "nro_hes",
          },
          {
            text: "Concepto",
            align: "start",
            value: "concepto",
          },
          {
            text: "Monto",
            align: "start",
            value: "monto",
          },
          {
            text: "Fecha Estimada Realización",
            align: "start",
            value: "fecha_estimada_realizacion",
          },

          {
            text: "Accion",
            value: "accion",
            sortable: false,
          },
        ];
      } else if (!this.datosFormulario.hes) {
        console.log("cambio");
        this.headers = [
          {
            text: "Concepto",
            align: "start",
            value: "concepto",
          },
          {
            text: "Monto",
            align: "start",
            value: "monto",
          },
          {
            text: "Fecha Estimada Realización",
            align: "start",
            value: "fecha_estimada_realizacion",
          },

          {
            text: "Accion",
            value: "accion",
            sortable: false,
          },
        ];
      }
    },
    getMandantes() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/mandantes/mandante/", config).then((response) => {
        this.mandantes = response.data;
        //this.loading = false;
      });
    },
    getLastNumber() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios.get("/proyectos/ultimoNumeroProyecto/", config).then((response) => {
        this.datosFormulario.nro_proyecto = response.data.nro_proyecto;
        console.log(response);
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      if (this.idDato) {
        axios.get("/proyectos/proyecto/" + this.idDato, config).then(async (response) => {
          console.log(response);
          this.datosFormulario = new datosReporte(response.data);
          for (let condiciones in this.datosFormulario.condiciones) {
            if (
              this.datosFormulario.condiciones[condiciones].fecha_estimada_realizacion
            ) {
              this.datosFormulario.condiciones[
                condiciones
              ].fecha_estimada_realizacion = moment(
                this.datosFormulario.condiciones[condiciones].fecha_estimada_realizacion,
                "YYYY-MM-DD"
              ).format("YYYY-MM-DD");
            } else {
              this.datosFormulario.condiciones[
                condiciones
              ].fecha_estimada_realizacion = null;
            }
            //console.log(condiciones);
          }

          for (let eventos in this.datosFormulario.eventos) {
            if (this.datosFormulario.eventos[eventos].fecha_estimada_realizacion) {
              this.datosFormulario.eventos[eventos].fecha_estimada_realizacion = moment(
                this.datosFormulario.eventos[eventos].fecha_estimada_realizacion,
                "YYYY-MM-DD"
              ).format("YYYY-MM-DD");
            } else {
              this.datosFormulario.eventos[eventos].fecha_estimada_realizacion = null;
            }
            //console.log(condiciones);
          }

          axios
            .get("/presupuestos/presupuesto/" + response.data.presupuestos[0]._id, config)
            .then((response) => {
              //this.datosFormulario.presupuestos.push(response.data);
              //this.datosFormulario.nombre = response.data.nombreProyecto;
              this.contactos_mandantes = response.data.mandante.contactos;
              //console.log(response);
            });
          /*this.status_bd = response.data.status;
          this.datosFormulario.fecha_envio = moment(
            this.datosFormulario.fecha_envio,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
          if (response.data.fecha_adjudicacion) {
            this.datosFormulario.fecha_adjudicacion = moment(
              this.datosFormulario.fecha_adjudicacion,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");
          }*/
        });
      } else {
      }
    },

    getDataInicial(nro_presupuesto) {
      console.log("llego");
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios
        .get("/presupuestos/presupuesto/" + nro_presupuesto, config)
        .then((response) => {
          this.datosFormulario.presupuestos.push(response.data);
          this.datosFormulario.nombre = response.data.nombreProyecto;
          this.contactos_mandantes = response.data.mandante.contactos;
          console.log(response);
        });
    },
    getencargados() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios.get("/proyectos/encargados/", config).then((response) => {
        this.encargados = response.data;
      });
    },

    async editData() {
      console.log("llego aca");
      this.loading = true;

      console.log(this.datosFormulario);

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        await axios
          .put("/proyectos/proyecto/" + this.idDato, this.datosFormulario, config)
          .then((response) => {
            this.$router.push({
              name: "proyectos",
            });

            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },

    async saveData() {
      console.log("llego aca");
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        //console.log(this.datosFormulario);
        await axios
          .post("/proyectos/proyecto/", this.datosFormulario, config)
          .then(async (response) => {
            setTimeout(() => (this.loading = false), 3000);

            let resi = await axios
              .put(
                "/presupuestos/asignarProyecto/" + this.$route.params.presupuesto,
                { idProyecto: response.data._id },
                config
              )
              .then((response2) => {
                this.$router.push({
                  name: "proyectos",
                });

                this.$toast.success(
                  "Grabado con exito!",
                  "",
                  this.notificationSystem.options.error
                );
              })
              .catch((e) => {
                this.loading = false;
                this.$toast.error(
                  "Ocurrio un error de sistema, vuelva a intentarlo",
                  "Error",
                  this.notificationSystem.options.error
                );
              });
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },
    getEstados(estado) {
      if (estado == true) return "green";
      else if (estado == false) return "red";
      else return "grey";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "Activo";
      else if (estado == false) return "Inactivo";
      else return "N/A";
    },
    //Contactos
    editItem(item) {
      this.editedIndex = this.datosFormulario.condiciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datosFormulario.condiciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.datosFormulario.condiciones.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      //this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        //Object.assign(this.desserts[this.editedIndex], this.editedItem);

        Object.assign(
          this.datosFormulario.condiciones[this.editedIndex],
          this.editedItem
        );
        //console.log("llego aca en >-1");
      } else {
        //console.log(this.editedItem);
        this.datosFormulario.condiciones.push(this.editedItem);
        //console.log("llego aca en push");
      }
      this.close();
    },

    //Eventos

    editItemEventos(item) {
      this.editedIndexEventos = this.datosFormulario.eventos.indexOf(item);
      this.editedItemEventos = Object.assign({}, item);
      this.dialogEventos = true;
    },

    deleteItemEventos(item) {
      this.editedIndexEventos = this.datosFormulario.eventos.indexOf(item);
      this.editedItemEventos = Object.assign({}, item);
      this.dialogDeleteEventos = true;
    },

    deleteItemConfirmEventos() {
      this.datosFormulario.eventos.splice(this.editedIndexEventos, 1);
      this.closeDeleteEventos();
    },

    closeEventos() {
      this.dialogEventos = false;
      this.$nextTick(() => {
        this.editedItemEventos = Object.assign({}, this.defaultItemEventos);
        this.editedIndexEventos = -1;
      });
      //this.editedIndex = -1;
    },

    closeDeleteEventos() {
      this.dialogDeleteEventos = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItemEventos);
        this.editedIndexEventos = -1;
      });
    },

    saveEventos() {
      if (this.editedIndexEventos > -1) {
        //Object.assign(this.desserts[this.editedIndex], this.editedItem);

        Object.assign(
          this.datosFormulario.eventos[this.editedIndexEventos],
          this.editedItemEventos
        );
        //console.log("llego aca en >-1");
      } else {
        //console.log(this.editedItem);
        this.datosFormulario.eventos.push(this.editedItemEventos);
        //console.log("llego aca en push");
      }
      this.closeEventos();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.idDato = this.$route.params.id;
    } else {
      if (!this.$route.params.presupuesto) {
        //this.idDato = this.$route.params.id;
        this.$router.push({
          name: "presupuestos",
        });
      } else {
        this.getLastNumber();
        this.datosFormulario.presupuestos = [];
        this.datosFormulario.condiciones = [];
        this.datosFormulario.hes = false;
        /*this.datosFormulario.presupuestos.push({
          tab: this.$route.params.presupuesto,
          content: "hola",
        });*/
        this.getDataInicial(this.$route.params.presupuesto);

        this.datosFormulario.status = "1";
        //this.getPresupuestos
      }
    }
    this.getencargados();
    this.getMandantes();
    this.getData();
  },
  mounted() {},
  computed: {
    ...mapState(["token", "usuarioDB", "itemsMenu", "notificationSystem"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Condición" : "Editar Condición";
    },
    formTitleEventos() {
      return this.editedIndexEventos === -1 ? "Nuevo Evento" : "Editar Evento";
    },
  },
  watch: {
    seleccionados: function (val) {
      this.datosFormulario.mods = [];
      for (let nodos of val) {
        this.datosFormulario.mods.push(nodos.id);
      }
    },
  },
  filters: {
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    fecha_normal_con_hora: function (valor) {
      return moment(valor, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    formato_numero(valor) {
      console.log(valor);
      return new Intl.NumberFormat("de-DE").format(valor);
    },
    salto_linea(valor) {
      return valor.replace(/\n/g, "<br>");
    },
  },
};
</script>
<style>
.v-label--active {
  color: #1c67fd !important;
  font-weight: bold;
}
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
